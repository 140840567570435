import BoardsCarousel from "./BoardsCarousel";
import FeaturesCarousel2 from "./FeaturesCarousel2";
import Navbar from '../../components/Navbar';
import Hero from "./Hero";
import { Link } from "react-router-dom";

function Home() {
    return (
        <div className="content">
            <Navbar showAppStoreButton={true} />

            {/* <div className="hero-section">
                <img className="hero" alt="hero" src={require("./hero-snbd.png")} />

                <h2 className="headline">Sound Player & Editor</h2>
                <p className="subheadline">Customize any sound on iOS and visionOS</p>
            </div> */}

            <Hero />

            <BoardsCarousel />

            <FeaturesCarousel2 />

            <div className="full">
                <div className="snbd-info-section">
                    <img className="folder" alt="folder" src={require("./folder-structure.png")} />
                    <div className="snbd-info-content">
                        <h2 className="folder-title">Behind The Boards</h2>
                        <span className="folder-description">Soundboards are just folders that include metadata and associated audio and photo files.</span>
                    </div>
                </div>
                <div className="footer">
                    <Link to="/presskit">Press Kit</Link>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                </div>
            </div>
        </div>
    );
}
  
export default Home;
  