import React from "react";
import InfoModalSourceRow from "./InfoModalSourceRow";

const InfoModalRow = ({ sound }) => {
    return (
        <div className={"info-modal-row"}>
            <span className="info-modal-sound-name">{sound.name}</span>

            { sound.audioSource ? (
                <InfoModalSourceRow 
                    isAudio={true} 
                    source={sound.audioSource} />
            ) : (
                null
            )}

            { sound.imageSource ? (
                <InfoModalSourceRow 
                    isAudio={false} 
                    source={sound.imageSource} />
            ) : (
                null
            )}

            {/* <span style={{ paddingLeft: "10px"}}>{title}</span>

            <img style={{ height: "28px", width: "28px", padding: "10px" }} 
            src ={require("../../../components/close.png")} 
            onClick={close}
            alt={`Close`} /> */}
        </div>
    )
}

export default InfoModalRow