import { Component } from "react";

class SoundCell extends Component {
    updatePlayback(audioURL) {
        let { updateNowPlaying } = this.props
        updateNowPlaying(audioURL)
    }

    // set background to transparent
    // set animation duration 
    // set background to opaque

    render() {
        const { boardURL, sound, duration } = this.props

        const alphabetArray = Array.from("abcdefghijklmnopqrstuvwxyz0123456789")
        const imageNameArray = sound.image ? Array.from(sound.image) : []

        var text = <p className="sound-cell-name">{ sound.name }</p>
        var imageView;
        if (sound.image === "jpeg" || sound.image === "png") {
            const imageURL = `${boardURL}/main/${encodeURIComponent(sound.name)}.${sound.image}`
            imageView = <img className="sound-image sound-icon" alt={`${sound.image} icon`} src={imageURL} />;

        } else if (imageNameArray.length > 0 && !alphabetArray.includes(imageNameArray[0])) {
            imageView = <span className="sound-emoji sound-icon">{sound.image}</span>;

        } else {
            text = null
            imageView = <span className="sound-image-text">{sound.name}</span>;
        }

        // console.log("dur", !duration, duration == 0)
    
        return (
        <div className="sound-cell" 
             onClick={this.updatePlayback.bind(this, sound)}>
            <div className="row-transparent-background"
                 style={{ backgroundColor: `#${sound.color ? sound.color : "007AFF"}`, 
                          opacity: 0.5 }} />

            <div className={`row-background ${!duration || duration === 0 ? "" : "animate"}`}
                 style={{   backgroundColor: `#${sound.color ? sound.color : "007AFF"}`, 
                            animationDuration: `${duration}s` }} />
                    
                 {/* </div> */}
                 <div className="cell-content" >
                    { imageView }
                    { text }
                 </div>
        </div>
        )
    }
}

export default SoundCell;
