import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

// const data = [
//   {
//     name: 'Animals',
//     image: 'https://repository-images.githubusercontent.com/658155335/04c2c09c-4d90-425c-9d50-861b36e4913e',
//     boardURL: 'https://github.com/snbd-app/Animals'
//   },
//   {
//     name: 'Sound Effects',
//     image: 'https://repository-images.githubusercontent.com/687646128/2649e285-b495-4ec1-a1db-1ef1e3be2d40',
//     boardURL: 'https://github.com/snbd-app/Sound_Effects'
//   },
//   {
//     name: 'Automobiles',
//     image: 'https://repository-images.githubusercontent.com/658156537/be573a16-4cec-4076-bbd0-d1d6d9047f94',
//     boardURL: 'https://github.com/snbd-app/Automobiles'
//   },
//   {
//     name: 'Numbers',
//     image: 'https://repository-images.githubusercontent.com/666071867/f0d0aa1e-b010-4676-91c0-976258482897',
//     boardURL: 'https://github.com/snbd-app/Numbers'
//   },
//   {
//     name: 'Instruments',
//     image: 'https://repository-images.githubusercontent.com/658156116/6bb63cea-9d93-4844-be84-226fc3aa048e',
//     boardURL: 'https://github.com/snbd-app/Instruments'
//   },
//   {
//     name: 'Alphabet',
//     image: 'https://repository-images.githubusercontent.com/663299009/50b92b52-2ac6-4c61-a506-0169220acfe1',
//     boardURL: 'https://github.com/snbd-app/Alphabet'
//   },
//   {
//     name: 'Birds',
//     image: 'https://repository-images.githubusercontent.com/658153450/ee3c9b94-0703-46fa-9379-adca7f1aca7a',
//     boardURL: 'https://github.com/snbd-app/Birds'
//   },
//   {
//     name: 'Colors',
//     image: 'https://repository-images.githubusercontent.com/663309413/583f0fb7-922a-4356-bba5-9f2c8496b0da',
//     boardURL: 'https://github.com/snbd-app/Colors'
//   },
//   {
//     name: 'Eagles',
//     image: 'https://repository-images.githubusercontent.com/633981579/a49e76fe-719c-4591-9b37-1cfa134c8c6c',
//     boardURL: 'https://github.com/teaseaque/Eagles'
//   }
// ]

class BoardsCarousel extends Component {
  state = {
    boards: [],
    isLoading: false,
    isTest: false
  };

  componentDidMount() {
    // if (this.state.isTest) {
    //   this.setList(data)
    // } else {
      this.fetchBoardsList()
    // }
  }

  fetchBoardsList() {
    // console.log("fetch board list")
    this.setState({
      isLoading: true
    })

    axios.get(`/api/boards-list`)
    .then(res => {
      // console.log("boards list front", res.data)
      this.setList(res.data)
    })
    .catch(err => {
      console.log(err)
    })
  }

  setList(boards) {
    this.setState({
      boards: boards,
      isLoading: false
    })
  }

  render() {
    let { boards } = this.state

    return (
      <div className="carousel-section">
        <div className="carousel-content">
          { boards.map(board => {

            var imageView;
            if (board.image) {
              imageView = <img className="carousel-image" alt={`${board.name} icon`} src={board.image} />;
            } else {
              imageView = <div className="carousel-image" />;
            }

            var view;
            if (board.boardURL) { 
              view = (
                <Link className="carousel-item" to={`/search?board=${board.boardURL}`}
                      state={{ imageURL: board.image }} 
                      key={board.name}>
                  { imageView }
                  <p className="carousel-text">{board.name}</p>
                </Link>
              )

            } else {
              view = (
                <div className="carousel-item" key={board.name}>
                  { imageView }
                  <p className="carousel-text placeholder"> </p>
                </div>
              )
            }

            return (
              view
            )
          })}
        </div>
      </div>
    )
  }
}

export default BoardsCarousel;
