import React from "react";

const InfoModalHeader = ({ title, close, placeholder }) => {
    return (
        <div className={"info-header"}>
            <span style={{ paddingLeft: "10px"}}>{title}</span>

            <img style={{ height: "28px", width: "28px", padding: "10px" }} 
            src ={require("../../../components/close.png")} 
            onClick={close}
            alt={`Close`} />
        </div>
    )
}

export default InfoModalHeader