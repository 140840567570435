// import BoardsCarousel from "./BoardsCarousel";
// import FeaturesCarousel from "./FeaturesCarousel";
import useWindowDimensions from './useWindowDimensions';

function Hero() {
    const { height, width } = useWindowDimensions();

    // console.log("tom", height, width)

    let lineBreakHero = width < 800 

    return (
        <div className={`${lineBreakHero ? "hero-container-v": "hero-container-h"}`}>
            <div className={`${lineBreakHero ? "leading-hero-v": "leading-hero-h"}`}>
                <h1 className='hero-title'>Sound Player & Editor</h1>
                <p className='hero-subtitle'>Customize any sound on iOS, visionOS, and more</p>
            </div>
            <div className={`${lineBreakHero ? "trailing-hero-v": "trailing-hero-h"}`}>
                <img className="hero-image" alt="hero" src={require("./hero-snbd.png")} />
            </div>    
        </div>
    );
}
  
export default Hero;
  