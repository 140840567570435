function PrivacyPolicy() {
    return (
    <div className="privacy-policy-page">
      <div className="privacy-policy">
        <h2 className="top-headline">Privacy Policy & Terms</h2>
        <p>I take your privacy very seriously. I neither want nor need to know anything about your personal data. To that end I’ve designed Snbd from the ground up to ensure that your data stays completely private.</p>
        <p>Snbd can optionally access your microphone to record new sounds using the standard iOS privacy prompts. If you give Snbd access to your microphone it will be read and displayed only within the app.</p>
        <p>No data ever leaves your devices unless you export it</p>
        <p>I don’t want the responsibility of managing your data correctly, so I don’t collect it, which I think is better for both of us.</p>
        <p>If you purchase Snbd Pro, the App Store manages your billing information, address, and name, and they are not visible to me.</p>
        <h2>Personally-Identifying Information</h2>
        <p>Snbd does not gather or transmit any Personally-Identifying Information outside of your local device. It only stores preferences about the user interface within its local database.</p>
        <h2>Privacy Policy Changes</h2>
        <p>Although most changes are likely to be minor, this Snbd Privacy Policy may change from time to time, and at Snbd’s sole discretion. Your continued app usage after changes in this Privacy Policy will constitute your acceptance of such change.</p>
        <h2>Indemnity</h2>
        <p>You hereby indemnify us and undertake to keep us indemnified against any losses, damages, costs, liabilities and expenses (including, without limitation, legal expenses and any amounts paid by us to a third party in settlement of a claim or dispute on the advice of our legal advisers) incurred or suffered by us arising out of use of this app or any breach by you of any provision of these terms of use, or arising out of any claim that you have breached any provision of these terms of use.</p>
        <h2>Updated: June 19, 2023 by Tom Kane</h2>
      </div>
      <div className="space">
        <span></span>
      </div>
    </div>
    );
  }
  
  export default PrivacyPolicy;
  