import React, { Component } from "react";

const features = [
  {
    name: 'Limitless Sound Boards',
    imageName: '1',
  },
  {
    name: 'Customize Everything',
    imageName: '2',
  },
  {
    name: 'Add Visuals To Sounds',
    imageName: '3',
  },
  {
    name: 'Trim Audio To Perfection',
    imageName: '4',
  },
  {
    name: 'Add To Your Home Screen',
    imageName: '5'
  },
  {
    name: 'Share Sounds and Boards',
    imageName: '6'
  }
]

class FeaturesCarousel2 extends Component {
  render() {
    return (
      <div className="carousel-section">
        <div className="carousel-content features">
          { features.map(feature => {
            const isLast = feature.name === features[features.length - 1].name
            return (
                <div className="outer-card" 
                    key={feature.name}>
                    {/* <div> */}
                        <img className="feature-image" alt={`${feature.name} icon`} src ={require(`./${feature.imageName}.png`) } />
                    {/* </div> */}
                </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default FeaturesCarousel2;
