import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivacyPolicy from './pages/PrivacyPolicy';
import Home from './pages/Home/Home';
import Board from './pages/Board/Board';
import Redirector from './components/Redirector';
import ShortURLs from './ShortURLs.json';

function App() {
  return (
    <BrowserRouter>
      <div className='window'>
          <Routes>
            {/* Main Pages */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/search" element={<Board />} />

            {/* ShortURL Redirect Pages */}
            {
              Object.keys(ShortURLs).map(shortURL => {
                return (
                  <Route path={`/${shortURL}`} element={<Redirector />} />
                )
              })
            }

            {/* <Route path='/presskit/' component={() => {
                window.location.href = 'https://drive.google.com/drive/folders/1PIqus4a6eIlRe3SkPuRCblzQoGtFHrxE';
                return null;
            }}/> */}

            <Route path="/presskit" element={<Redirector />} />

            {/* Catch All Else to Landing */}
            <Route path="/*" element={<Home />} />
          </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;