import React from "react";
import InfoModalHeader from "./InfoModalHeader";
import InfoModalRow from "./InfoModalRow";
import InfoModalSourceRow from "./InfoModalSourceRow";

const InfoModal = ({ show, image, title, audioSource, imageSource, sounds, close }) => {
    if (!show) return null

    let hasBoardSources = audioSource || imageSource
    
    return (
        <div className="info-modal-container"> 
            <div className="info-modal-background" onClick={close} />

            <div className="info-modal">
                <InfoModalHeader title={title} close={close} />
                <div className="info-content-container">
                    <div className="info-content">
                        {
                            hasBoardSources ? (
                                <div className="info-modal-board-sources">
                                    { audioSource ? (
                                        <InfoModalSourceRow
                                            isAudio={true} 
                                            source={audioSource} />
                                    ) : (
                                        null
                                    )}
        
                                    { imageSource ? (
                                        <InfoModalSourceRow
                                            isAudio={false} 
                                            source={imageSource} />
                                    ) : (
                                        null
                                    )}
                                </div>
                            ) : (
                                null
                            )
                        }

                        {
                            sounds.map(sound => {
                                return (
                                    <InfoModalRow sound={sound} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoModal