import Markdown from 'react-markdown'

const InfoModalSourceRow = ({ isAudio, source }) => {
    return (
        <div className={"info-modal-source-row"}>
            <span>{isAudio ? "Audio:  " : "Image: "}</span>
            <Markdown>{source}</Markdown>
        </div>
    )
}

export default InfoModalSourceRow