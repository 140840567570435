import { Component } from "react";
// import axios from "axios";
// import SoundCell from "./SoundCell";
// import Navbar from '../../components/Navbar';
// import BoardImage from "./BoardImage"
import { Navigate } from "react-router-dom";

var ShortURLs = require("../ShortURLs.json")

class Redirector extends Component {

    getPath() {
        const path = document.location.pathname

        console.log(path)
        if (ShortURLs[path.toLowerCase().substring(1)]) {
            return "/search?board=" + ShortURLs[path.toLowerCase().substring(1)]

        } else if (path.includes("/presskit")) {
            window.location.replace('https://drive.google.com/drive/folders/1PIqus4a6eIlRe3SkPuRCblzQoGtFHrxE')
        }
        
        return "/"
    }

    render() {     
        return (
            <Navigate to={`${this.getPath()}`} />
        )
    }
}

export default Redirector;
