import { Component } from "react";
import axios from "axios";
import SoundCell from "./SoundCell";
import Navbar from '../../components/Navbar';
import InfoModal from "./InfoModal/InfoModal";
// import BoardImage from "./BoardImage"

var dummyBoard = require("../../dummyBoard.json")
var dummyImageURL = "https://repository-images.githubusercontent.com/658153450/ee3c9b94-0703-46fa-9379-adca7f1aca7a"

class Board extends Component {
  state = {
    image: "",
    title: null,
    description: "",
    audioSource: "",
    imageSource: "",
    error: "",
    description: " ",
    sounds: [],
    boardURL: "",
    isTest: false,
    nowPlayingAudio: null,
    duration: 0,
    showInfoModal: false
  };

  componentDidMount() {   
    if (this.state.isTest) {
      // console.log("mount board") 
      let boardURL = "https://github.com/snbd-app/birds"
      var usedDummyBoardURL = boardURL.toLowerCase().replace("github", "raw.githubusercontent")
      this.updateBoard(dummyBoard)
      this.setState({ image: dummyImageURL, 
                      boardURL: usedDummyBoardURL })

    } else {

      const boardURL = new URLSearchParams(document.location.search).get("board")
      const usedURL = this.props && this.props.url ? this.props.url : boardURL

      // console.log("tx", boardURL, "||", this.props.url, "=", usedURL)

      if (usedURL) {
        var usedBoardURL = usedURL.toLowerCase().replace("github", "raw.githubusercontent")

        this.setState({
          boardURL: usedBoardURL
        })
    
        this.setTitleAndImageFromOG()
        this.fetchBoard(usedBoardURL)
      }
    }
  }

  componentWillUnmount() {
    const { nowPlayingAudio } = this.state

    if (nowPlayingAudio) {
      this.updateNowPlaying(null)
    }
  }

  setTitleAndImageFromOG() {
    if (document.getElementById('og:image') && document.getElementById('og:image').content) {
      const content = document.getElementById('og:image').content

      if (content !== "https://snbd.app/favicon.ico" && content !== "__META_OG_IMAGE__") {
        this.setState({
          image: document.getElementById('og:image').content
        })
      }
    } 
  }

  fetchBoard(boardURL) {
    axios.get(`/api/board?boardURL=${boardURL}/main`)
    .then(res => {
      this.updateBoard(res.data)
    })
    .catch(err => {
      this.setState({
        error: "Sound Board Not Found"
      })
      console.log(err.message)
    })
  }

  updateBoard(board) {
    this.setState({
      title: board.name,
      description: board.description,
      audioSource: board.audioSource,
      imageSource: board.imageSource,
      sounds: board.sounds
    })
  }

  updateNowPlaying(sound) {
    let { boardURL, nowPlayingAudio } = this.state

    if (nowPlayingAudio) {
      nowPlayingAudio.pause()
      this.onSoundEnd()  
    }

    if (sound && sound.name) {
      const fileExtension = sound.type ? sound.type : "mp3"
      const audioURL = `${boardURL}/main/${encodeURIComponent(sound.name)}.${fileExtension}`

      if (!nowPlayingAudio || nowPlayingAudio.src !== audioURL) {
        var newAudio = new Audio(audioURL)
        newAudio.onended = this.onSoundEnd.bind(this)
        this.setState({
          nowPlayingAudio: newAudio
        })

        // console.log("ys1", newAudio.duration)
        
        newAudio.play()
        .then( res => {
          // console.log("ys2", newAudio.duration)
          this.setState({
            duration: newAudio.duration
          })  
        }).catch( err => {
          // console.log("play error", err)
          this.setState({
            nowPlayingAudio: null,
            duration: 0
          })
        })
      }
    }
  }

  onSoundEnd() {
    this.setState({
      nowPlayingAudio: null,
      duration: 0
    })
  }

  toggleInfoModal = (_, show) => {
    const { showInfoModal } = this.state
    let newValue = show != null ? show : !showInfoModal
    // console.log("new", show, newValue)
    this.setState({ showInfoModal: newValue })
  }

  render() {
    const { image, title, audioSource, imageSource, sounds, boardURL, duration, nowPlayingAudio, showInfoModal } = this.state
    
    return (
      <div>
      <div className={`content ${showInfoModal ? "limit-scroll" : ""}`}>
        <Navbar 
        showAppStoreButton={false} 
        boardImageURL={image} 
        boardName={title} 
        toggleInfoModal={ this.toggleInfoModal.bind(this) } 
        />

        <div className="board">
          <InfoModal 
            show={showInfoModal} 
            title={title} 
            audioSource={audioSource}
            imageSource={imageSource}
            sounds={sounds}
            close={this.toggleInfoModal.bind(false)}
             />
          <div className="grid">
            {
              sounds && sounds.length > 0 ? (
                sounds.map((sound, i) => {

                  let usedDuration = nowPlayingAudio && nowPlayingAudio.src && nowPlayingAudio.src === `${boardURL}/main/${encodeURIComponent(sound.name)}.${sound.type ? sound.type : "mp3"}` ? duration : 0

                  return (<SoundCell  boardURL={boardURL} 
                                      sound={sound} 
                                      duration={ usedDuration } 
                                      updateNowPlaying={ this.updateNowPlaying.bind(this) } 
                                      key={i} />)
                })  
              ) : (
                <div />
              )
            }
          </div>
        </div>

      </div>

      </div>
    )
  }
}

export default Board;
