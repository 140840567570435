import { useLocation } from 'react-router-dom'
import { useEffect } from 'react';
import axios from "axios";

function Navbar({ showAppStoreButton, boardImageURL, boardName, toggleInfoModal }) {
    const location = useLocation()

    const usedImage = location.state && location.state.imageURL ? location.state.imageURL : boardImageURL

    // done here because it needs to be within Router
    useEffect(() => {
        postMetadata()
    }, [location]);    

    function postMetadata() {
        let pageView = {
          path: location.pathname,
          search: location.search
        };
    
        axios
        .post(`api/metadata`, pageView)
        .catch(err => {
          // console.log("metadata error", err.message)
        })
      }

    return (
        <div className="navigation">
            <a href="/">
                <img className="nav-snbd-icon" alt="Snbd icon" src={usedImage == null ? require("../snbd-icon.png") : usedImage} />
            </a>
            <a href="/" className="nav-title">
                <span>{boardName == null ? "Snbd" : boardName}</span>
            </a>
            {
                toggleInfoModal != null ? (
                    <img style={{ height: "26px", width: "26px" }} 
                    className="info-button" 
                    src ={require("./info.png")} 
                    onClick={toggleInfoModal}
                    alt={`Info`} />
                ) : (
                     null 
                )
            }

            <div className="align-trail-container">
                <a className="get-snbd-button downloadOnAppStoreImage" href="https://apps.apple.com/app/id6446608944">
                    <span>Get Snbd</span>
                </a>
            </div>
        </div>
    );
}

export default Navbar;